// Request methods & other constants.
export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";
export const CONTENT_TYPE_JSON = "application/json";
export const APPLICATION_OCTET_STREAM = "application/octet-stream";

// Async variable names.
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const USER_ID = "USER_ID";
export const ACCESS_TOKEN_RAPID = "ACCESS_TOKEN_RAPID";
export const REFRESH_TOKEN_RAPID = "REFRESH_TOKEN_RAPID";
export const USER_ID_RAPID = "USER_ID_RAPID";
export const ACCESS_TOKEN_TIME = "ACCESS_TOKEN_TIME";
export const USER_NAME = "USER_NAME";
export const TIME = "TIME";
export const DISTANCE = "DISTANCE";
export const REPORTDATA = "REPORTDATA";
export const VIDEO_CERDENTIAL = "VIDEO_CERDENTIAL";
export const SESSON_USER = "SESSON_USER";
export const AES_KEY = "AES_KEY";
export const PRIVATE_KEY = "PRIVATE_KEY";
export const PHONE_NUMBER = "PHONE_NUMBER";
export const USER_ADMIN = 'USER_ADMIN';


export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
