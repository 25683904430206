import React, { Fragment } from "react";
import "./App.css";
import Routes from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/toastr/build/toastr.css";
// import "~slick-carousel/slick/slick.css";
// import "../node_modules/slick-carousel/";
// import "../node_modules/";
// import "~slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const App = () => {
  return (
    <Fragment>
      <ToastContainer theme="colored" />
      <Routes />
    </Fragment>
  );
};

export default App;
