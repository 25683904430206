
export const residentialLand =  [
    { label: "Property Shown By (Name)", field: "text", options: [],key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Society/ Name Board Available at site", key:"society_name_board_available_at_site", field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Demarcation avaialable Site(Y/N)", key:"demarcation_available_at_site",field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Land Area (In Sqft)",key:"land_area_in_sqft", field: "number", options: [] },
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },
    { label: "Property located in ",key:"property_located_in_m_da_gp",   field: "dropdown",  options: [{ label: "Municipality", value: "Municipality" }, { label: "DA", value: "DA" }, { label: "GP", value: "GP" } ]}, 
    { label: "Road Width (frontage)",key:"road_width", field: "text", options: [] },
    { label: "Boundaries", field: "boundry", options: [] },
  ];


  export const residentialFlat = [
    { label: "Property Shown By (Name)", field: "text", options: [] ,key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Society/ Name Board Available at site",key:"society_name_board_available_at_site", field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Age of Building", field: "number", key:"age_of_building", options: [] },
    { label: "Occupied since", field: "number", key:"occupied_since", options: [] },
    { label: "Leasable/Carpet/SBUA area at site in Sqft", key:"leasable_carpet_sbua_area_at_site_in_sqft", field: "number", options: [] },
   
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },
    { label: "Property located in ",key:"property_located_in_m_da_gp",   field: "dropdown",  options: [{ label: "Municipality", value: "Municipality" }, { label: "DA", value: "DA" }, { label: "GP", value: "GP" } ]}, 
    { label: "Number of flats per floor",key:"number_of_flats_per_floor", field: "number", options: [] },
    { label: "Number of wings/blocks in society",key:"number_of_wings_blocks_in_society", field: "number", options: [] },
    { label: "Number of floors in the building",key:"number_of_floors_in_the_building", field: "number", options: [] },
    { label: "Flat Specification BHK", key:"flat_specification_bhk",field: "text", options: [] },
    { label: "Boundaries", field: "boundry", options: [] },
  ];
  
  export const residentialHouse = [
    { label: "Property Shown By (Name)", field: "text", options: [] ,key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Society/ Name Board Available at site",key:"society_name_board_available_at_site", field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Age of Building", field: "number", key:"age_of_building", options: [] },
    { label: "Demarcation avaialable Site(Y/N)", key:"demarcation_available_at_site",field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Number of tenants", field: "number", key:"number_of_tenants", options: [] },
    { label: "Occupied since", field: "number", key:"occupied_since", options: [] },
    { label: "Land Area (In Sqft)",key:"land_area_in_sqft", field: "number", options: [] },
    { label: "BUA in Sqft",key:"bua_in_sqft", field: "number", options: [] },
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },
    { label: "Property located in ",key:"property_located_in_m_da_gp",  field: "dropdown",  options: [{ label: "Municipality", value: "Municipality" }, { label: "DA", value: "DA" }, { label: "GP", value: "GP" } ]}, 
    { label: "Road Width (frontage)",key:"road_width", field: "text", options: [] },
    { label: "Number of floors in the building",key:"number_of_floors_in_the_building", field: "number", options: [] },
    { label: "Flat Specification BHK", key:"flat_specification_bhk",field: "text", options: [] },
    { label: "Number of units (If applicable)", key:"number_of_units",field: "number", options: [] },
    { label: "Boundaries", field: "boundry", options: [] },
  ];


  export const commercialLand =  [
    { label: "Property Shown By (Name)", field: "text", options: [],key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Society/ Name Board Available at site", key:"society_name_board_available_at_site", field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Demarcation avaialable Site(Y/N)", key:"demarcation_available_at_site",field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Land Area (In Sqft)",key:"land_area_in_sqft", field: "number", options: [] },
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },
    { label: "Property located in ",key:"property_located_in_m_da_gp",   field: "dropdown",  options: [{ label: "Municipality", value: "Municipality" }, { label: "DA", value: "DA" }, { label: "GP", value: "GP" } ]}, 
    { label: "Road Width (frontage)",key:"road_width", field: "text", options: [] },
    { label: "Boundaries", field: "boundry", options: [] },
  ];


  export const commercialOffice = [
    { label: "Property Shown By (Name)", field: "text", options: [] ,key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Society/ Name Board Available at site",key:"society_name_board_available_at_site", field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Age of Building", field: "number", key:"age_of_building", options: [] },
    { label: "Demarcation avaialable Site(Y/N)", key:"demarcation_available_at_site",field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Number of tenants", field: "number", key:"number_of_tenants", options: [] },
    { label: "Name of tenants", field: "text", key:"name_of_tenant", options: [] },
    { label: "Occupied since", field: "number", key:"occupied_since", options: [] },
    { label: "Leasable/Carpet/SBUA area at site in Sqft", key:"leasable_carpet_sbua_area_at_site_in_sqft", field: "number", options: [] },
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },
    { label: "Property located in ",key:"property_located_in_m_da_gp",   field: "dropdown",  options: [{ label: "Municipality", value: "Municipality" }, { label: "DA", value: "DA" }, { label: "GP", value: "GP" } ]}, 
    { label: "Boundaries", field: "boundry", options: [] },
  ];

  export const commercialRetail = [
    { label: "Property Shown By (Name)", field: "text", options: [] ,key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Society/ Name Board Available at site",key:"society_name_board_available_at_site", field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Age of Building", field: "number", key:"age_of_building", options: [] },
    { label: "Demarcation avaialable Site(Y/N)", key:"demarcation_available_at_site",field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Number of tenants", field: "number", key:"number_of_tenants", options: [] },
    { label: "Name of tenants", field: "text", key:"name_of_tenant", options: [] },
    { label: "Occupied since", field: "number", key:"occupied_since", options: [] },
  ];

  export const commercialBuilding = [
    { label: "Property Shown By (Name)", field: "text", options: [] ,key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Society/ Name Board Available at site",key:"society_name_board_available_at_site", field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Age of Building", field: "number", key:"age_of_building", options: [] },
    { label: "Demarcation avaialable Site(Y/N)", key:"demarcation_available_at_site",field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Number of tenants", field: "number", key:"number_of_tenants", options: [] },
    { label: "Name of tenants", field: "text", key:"name_of_tenant", options: [] },
    { label: "Occupied since", field: "number", key:"occupied_since", options: [] },
    { label: "Land Area (In Sqft)",key:"land_area_in_sqft", field: "number", options: [] },
    { label: "BUA in Sqft",key:"bua_in_sqft", field: "number", options: [] },
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },
    { label: "Property located in ",key:"property_located_in_m_da_gp",   field: "dropdown",  options: [{ label: "Municipality", value: "Municipality" }, { label: "DA", value: "DA" }, { label: "GP", value: "GP" } ]}, 
    { label: "Number of floors in the building",key:"number_of_floors_in_the_building", field: "number", options: [] },
    { label: "Number of units (If applicable)", key:"number_of_units",field: "number", options: [] },
    { label: "Boundaries", field: "boundry", options: [] },

  ];


  export const industrialLand = [
    { label: "Property Shown By (Name)", field: "text", options: [] ,key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Society/ Name Board Available at site",key:"society_name_board_available_at_site", field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Demarcation avaialable Site(Y/N)", key:"demarcation_available_at_site",field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Land Area (In Sqft)",key:"land_area_in_sqft", field: "number", options: [] },
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },   
    { label: "Property located in",key:"property_located_in_gov_pv_standalone", field: "dropdown",  options: [{ label: "Gov. Promoted", value: "Gov Promoted" }, { label: "Private industrial estate", value: "Private industrial estate" }, { label: "Standalone", value: "Standalone" } ]},
    { label: "Surrounding Area development",key:"surrounding_area_development", field: "dropdown",  options: [{ label: "Industrial", value: "Industrial" }, { label: "Mixed", value: "Mixed" }, { label: "Vacant", value: "Vacant" } ]},
    { label: "Road Width (frontage)",key:"road_width", field: "text", options: [] },
    { label: "Boundaries", field: "boundry", options: [] },
  ];



  export const industrialUnit = [
    { label: "Property Shown By (Name)", field: "text", options: [] ,key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Society/ Name Board Available at site",key:"society_name_board_available_at_site", field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    // { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Age of Building", field: "number", key:"age_of_building", options: [] },
    { label: "Demarcation avaialable Site(Y/N)", key:"demarcation_available_at_site",field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Land Area (In Sqft)",key:"land_area_in_sqft", field: "number", options: [] },
    { label: "BUA in Sqft",key:"bua_in_sqft", field: "number", options: [] },
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },   
    { label: "Property located in",key:"property_located_in_gov_pv_standalone", field: "dropdown",  options: [{ label: "Gov. Promoted", value: "Gov Promoted" }, { label: "Private industrial estate", value: "Private industrial estate" }, { label: "Standalone", value: "Standalone" } ]},
    { label: "Surrounding Area development",key:"surrounding_area_development", field: "dropdown",  options: [{ label: "Industrial", value: "Industrial" }, { label: "Mixed", value: "Mixed" }, { label: "Vacant", value: "Vacant" } ]},
    { label: "Road Width (frontage)",key:"road_width", field: "text", options: [] },
    { label: "No of buildings on site",key:"no_of_buildings_on_site", field: "number", options: [] },
    { label: "Type of Construction",key:"type_of_construction", field: "text", options: [] },
    { label: "Boundaries", field: "boundry", options: [] },
  ];

  export const propertyHospital = [
    { label: "Property Shown By (Name)", field: "text", options: [] ,key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    
    { label: "Age of Building", field: "number", key:"age_of_building", options: [] },
    { label: "Land Area (In Sqft)",key:"land_area_in_sqft", field: "number", options: [] },
    { label: "BUA in Sqft",key:"bua_in_sqft", field: "number", options: [] },
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },   
    { label: "Property located in ",key:"property_located_in_m_da_gp",   field: "dropdown",  options: [{ label: "Municipality", value: "Municipality" }, { label: "DA", value: "DA" }, { label: "GP", value: "GP" } ]}, 
    { label: "Road Width (frontage)",key:"road_width", field: "text", options: [] },
    { label: "No of beds",key:"road_width", field: "text", options: [] },
    { label: "Boundaries", field: "boundry", options: [] },


  ];

  
  export const propertyMarriage = [
    { label: "Property Shown By (Name)", field: "text", options: [] ,key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Age of Building", field: "number", key:"age_of_building", options: [] },
    { label: "Demarcation avaialable Site(Y/N)", key:"demarcation_available_at_site",field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Land Area (In Sqft)",key:"land_area_in_sqft", field: "number", options: [] },
    { label: "BUA in Sqft",key:"bua_in_sqft", field: "number", options: [] },
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },   
    { label: "Property located in ",key:"property_located_in_m_da_gp",   field: "dropdown",  options: [{ label: "Municipality", value: "Municipality" }, { label: "DA", value: "DA" }, { label: "GP", value: "GP" } ]} ,
    { label: "Road Width (frontage)",key:"road_width", field: "text", options: [] },
    { label: "Ground Coverage>20% land portion",key:"ground_coverage_gt_20_per_of_land_portion", field: "text", options: [] },
    { label: "Boundaries", field: "boundry", options: [] },
  ];

  export const propertyInstitude = [
    { label: "Property Shown By (Name)", field: "text", options: [] ,key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Age of Building", field: "number", key:"age_of_building", options: [] },
    { label: "Demarcation avaialable Site(Y/N)", key:"demarcation_available_at_site",field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Land Area (In Sqft)",key:"land_area_in_sqft", field: "number", options: [] },
    { label: "BUA in Sqft",key:"bua_in_sqft", field: "number", options: [] },
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },   
    { label: "Property located in ",key:"property_located_in_m_da_gp",  field: "dropdown",  options: [{ label: "Municipality", value: "Municipality" }, { label: "DA", value: "DA" }, { label: "GP", value: "GP" } ]}, 
    { label: "Road Width (frontage)",key:"road_width", field: "text", options: [] },
    { label: "No of Students",key:"no_of_students", field: "number", options: [] },
    { label: "No of Teaching and Non Teaching staff",key:"no_of_teaching_and_non_teaching_staff", field: "number", options: [] },
    { label: "Average Student fee",key:"average_student_fee", field: "number", options: [] },
    { label: "Average Staff salary",key:"average_staff_salary", field: "number", options: [] },
    { label: "Affiliation",key:"affiliation", field: "text", options: [] },
    { label: "Boundaries", field: "boundry", options: [] },
  ];


  export const propertyCinima  = [
    { label: "Property Shown By (Name)", field: "text", options: [] ,key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Age of Building", field: "number", key:"age_of_building", options: [] },
    { label: "Demarcation avaialable Site(Y/N)", key:"demarcation_available_at_site",field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Number of tenants", field: "number", key:"number_of_tenants", options: [] },
    { label: "Land Area (In Sqft)",key:"land_area_in_sqft", field: "number", options: [] },
    { label: "BUA in Sqft",key:"bua_in_sqft", field: "number", options: [] },
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },   
    { label: "Property located in ",key:"property_located_in_m_da_gp",   field: "dropdown",  options: [{ label: "Municipality", value: "Municipality" }, { label: "DA", value: "DA" }, { label: "GP", value: "GP" } ]}, 
    { label: "Road Width (frontage)",key:"road_width", field: "text", options: [] },
    { label: "No of Screens",key:"no_of_screens", field: "number", options: [] },
    { label: "Total Capacity",key:"total_capacity", field: "number", options: [] },
    { label: "Boundaries", field: "boundry", options: [] },
  ];


  export const propertyMalls = [
    { label: "Property Shown By (Name)", field: "text", options: [] ,key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Age of Building", field: "number", key:"age_of_building", options: [] },
    { label: "Demarcation avaialable Site(Y/N)", key:"demarcation_available_at_site",field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Land Area (In Sqft)",key:"land_area_in_sqft", field: "number", options: [] },
    { label: "BUA in Sqft",key:"bua_in_sqft", field: "number", options: [] },
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },   
    { label: "Property located in ",key:"property_located_in_m_da_gp",  field: "dropdown",  options: [{ label: "Municipality", value: "Municipality" }, { label: "DA", value: "DA" }, { label: "GP", value: "GP" } ]} ,
    { label: "Road Width (frontage)",key:"road_width", field: "text", options: [] },
    { label: "Occupancy %", key:"occupancy_per", field: "text", options: [] },
    { label: "Boundaries", field: "boundry", options: [] },
  ];

  export const propertyHotels = [
    { label: "Property Shown By (Name)", field: "text", options: [] ,key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Age of Building", field: "number", key:"age_of_building", options: [] },
    { label: "Demarcation avaialable Site(Y/N)", key:"demarcation_available_at_site",field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Land Area (In Sqft)",key:"land_area_in_sqft", field: "number", options: [] },
    { label: "BUA in Sqft",key:"bua_in_sqft", field: "number", options: [] },
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },   
    { label: "Property located in ",key:"property_located_in_m_da_gp",  field: "dropdown",  options: [{ label: "Municipality", value: "Municipality" }, { label: "DA", value: "DA" }, { label: "GP", value: "GP" } ]}, 
    { label: "Road Width (frontage)",key:"road_width", field: "text", options: [] },
    { label: "Occupancy %", key:"occupancy_per", field: "text", options: [] },
    { label: "No of keys", key:"no_of_keys", field: "number", options: [] },
    { label: "Avg room rate", key:"avg_room_rate", field: "number", options: [] },
    { label: "No of restaurants", key:"no_of_restaurants", field: "number", options: [] },
    { label: "No of Banquet halls/meeting rooms", key:"no_of_banquet_halls_meeting_rooms", field: "number", options: [] },
    { label: "Occupancy rate", key:"occupancy_rate", field: "text", options: [] },
    { label: "Boundaries", field: "boundry", options: [] },
  ];

  
  export const propertyWarehouses = [
    { label: "Property Shown By (Name)", field: "text", options: [] ,key:"property_shown_by" },
    { label: "Property Address (As per Site)", field: "text", options: [],key:"address" },
    { label: "Society/ Name Board Available at site",key:"society_name_board_available_at_site", field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Landmark", field: "text", options: [],key:"landmark" },
    { label: "Occupancy Status", key:"occupancy_status", field: "dropdown", options: [{ label: "Self Occupied", value: "Self Occupied" }, { label: "Vacant", value: "Vacant" }, { label: "Rented", value: "Rented" }, { label: "UC", value: "UC" }] },
    { label: "Age of Building", field: "number", key:"age_of_building", options: [] },
    { label: "Demarcation avaialable Site(Y/N)", key:"demarcation_available_at_site",field: "dropdown", options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }] },
    { label: "Land Area (In Sqft)",key:"land_area_in_sqft", field: "number", options: [] },
    { label: "BUA in Sqft",key:"bua_in_sqft", field: "number", options: [] },
    { label: "Critical Observations",key:"critical_observations", field: "text", options: [] },   
    { label: "Property located in",key:"property_located_in_gov_pv_standalone", field: "dropdown",  options: [{ label: "Gov. Promoted", value: "Gov Promoted" }, { label: "Private industrial estate", value: "Private industrial estate" }, { label: "Standalone", value: "Standalone" } ]},
    { label: "Surrounding Area development",key:"surrounding_area_development", field: "dropdown",  options: [{ label: "Industrial", value: "Industrial" }, { label: "Mixed", value: "Mixed" }, { label: "Vacant", value: "Vacant" } ]},
    { label: "Road Width (frontage)",key:"road_width", field: "text", options: [] },
    { label: "No of buildings on site",key:"no_of_buildings_on_site", field: "number", options: [] },
    { label: "Type of Construction",key:"type_of_construction", field: "text", options: [] },
    { label: "Boundaries", field: "boundry", options: [] },
  ];
